import axios from "axios";

export async function getListMachine(params) {
    const res = await axios.get('/machine/list', params);
    return res.data;
}
export async function storePallet(params) {
    const res = await axios.post('/lot/store', params);
    return res;
}
export async function getListLot(params) {
    const res = await axios.get('/lot/list-table', { params: params });
    return res.data;
}
export async function getListWarehouseExportPlan(params) {
    const res = await axios.get('/warehouse/list-export-plan', { params });
    return res.data;
}
export async function getListProductPlan(params) {
    const res = await axios.get('/production-plan/list', { params });
    return res.data;
}
export async function updateStatusProductionPlan(id, params) {
    const res = await axios.put('/production-plan/status-plan/' + id, params);
    return res;
}
export async function getProposeWareHouse(params) {
    const res = await axios.get('/warehouse/propose-import', { params: params });
    return res.data;
}
export async function importWareHouse(params) {
    const res = await axios.post('/warehouse/import', params);
    return res.data;
}
export async function getListImportWareHouse() {
    const res = await axios.get('/warehouse/list-import');
    return res.data;
}
export async function getInfoImportWareHouse() {
    const res = await axios.get('/warehouse/info-import');
    return res.data;
}
export async function getListCustomerExport() {
    const res = await axios.get('/warehouse/list-customer');
    return res.data;
}
export async function getProposeExportWareHouse(params) {
    const res = await axios.get('/warehouse/propose-export', { params: params });
    return res.data;
}
export async function exportWareHouse(params) {
    const res = await axios.post('/warehouse/export', params);
    return res.data;
}
export async function getInfoExportWareHouse() {
    const res = await axios.get('/warehouse/info-export');
    return res.data;
}
export async function getListMaterialLog(params) {
    const res = await axios.get('/material/list-log', { params });
    return res.data;
}
export async function updateMaterialLog(params) {
    const res = await axios.post('/material/update-log', params);
    return res.data;
}
export async function getListLsxUseMaterial(params) {
    const res = await axios.get('/material/list-lsx', { params: params });
    return res.data;
}
export async function splitBarrel(params) {
    const res = await axios.post('/barrel/split', params);
    return res.data;
}
export async function getHistoryWareHouse(params) {
    const res = await axios.get('/warehouse/history', { params: params });
    return res.data;
}
export async function updateWarehouseInventory(params) {
    const res = await axios.post('/warehouse/update-inventory', params);
    return res;
}
export async function deletePallet(params) {
    const res = await axios.delete('/pallet/destroy', { params: params });
    return res.data;
}
export async function updateMaterialLogRecord(params) {
    const res = await axios.post('/material/update-log-record', params);
    return res.data;
}
export async function storeMaterialLog(params) {
    const res = await axios.post('/material/store-log', params);
    return res.data;
}
export async function deleteRecordProductPlan(params) {
    const res = await axios.delete('/product_plan/destroy', { params: params });
    return res.data;
}
export async function updateProductPlan(params) {
    const res = await axios.post('/product_plan/update', params);
    return res.data;
}
export async function storeProductPlan(params) {
    const res = await axios.post('/product_plan/store', params);
    return res.data;
}
export async function deleteWareHouseExport(params) {
    const res = await axios.delete('/warehouse-export/destroy', { params: params });
    return res.data;
}
export async function updateWareHouseExport(params) {
    const res = await axios.post('/warehouse-export/update', params);
    return res.data;
}
export async function createWareHouseExport(params) {
    const res = await axios.post('/warehouse-export/create', params);
    return res.data;
}
export async function testUpdateTable(params) {
    const res = await axios.post('/update/test', params);
    return res.data;
}

export async function getListScenario() {
    const res = await axios.get('/scenario/list');
    return res.data;
}

export async function updateScenario(params) {
    const res = await axios.post('/scenario/update', params);
    return res.data;
}
export async function getHistoryMonitor(params) {
    const res = await axios.get('/monitor/history', { params: params });
    return res.data;
}
export async function updateMonitor(params) {
    const res = await axios.post('/monitor/update', params);
    return res.data;
}
export async function getDetailLot(params) {
    const res = await axios.get('/lot/detail', { params: params });
    return res.data;
}
export async function getInfoChon(params) {
    const res = await axios.get('/info/chon', { params: params });
    return res;
}
export async function exportSummaryWarehouse(params) {
    const res = await axios.get('export/warehouse/summary', { params: params });
    return res;
}
export async function exportBMCardWarehouse(params) {
    const res = await axios.get('export/warehouse/bmcard', { params: params });
    return res;
}
export async function exportInventoryWarehouse(params) {
    const res = await axios.get('export/warehouse/inventory', { params: params });
    return res;
}
export async function getStatusIOT() {
    const res = await axios.get('/iot/status');
    return res.data;
}
export async function prepareGT(params) {
    const res = await axios.get('/warehouse-export/get-thung', { params });
    return res.data;
}
export async function gopThung(params) {
    const res = await axios.post('/warehouse-export/gop-thung', params);
    return res.data;
}
export async function exportHistoryMonitors(params) {
    const res = await axios.get('/export/history-monitors', { params });
    return res;
}
export async function taoTem(params) {
    const res = await axios.post('/tao-tem', params);
    return res.data;
}
export async function listProduct() {
    const res = await axios.get('/list-product');
    return res.data;
}

//InfoCongDoan
export async function getInfoCongDoan(params) {
    const res = await axios.get('/info-cong-doan/list', { params });
    return res.data;
}
export async function searchInfoCongDoan(params) {
    const res = await axios.get('/info-cong-doan/search', { params });
    return res.data;
}
export async function updateInfoCongDoan(params) {
    const res = await axios.post('/info-cong-doan/update', params);
    return res.data;
}
export async function exportInfoCongDoan(params) {
    const res = await axios.get('/info-cong-doan/export', { params });
    return res;
}

//Machine
export async function getMachine(params) {
    const res = await axios.get('/machine/list', { params });
    return res.data;
}
export async function createMachine(params) {
    const res = await axios.post('/machine/create', params);
    return res.data;
}
export async function updateMachine(params) {
    const res = await axios.patch('/machine/update', params);
    return res.data;
}
export async function deleteMachine(params) {
    const res = await axios.post('/machine/delete', params);
    return res.data;
}
export async function exportMachine(params) {
    const res = await axios.get('/machine/export', { params });
    return res;
}
export async function exportMaintenanceDetailStatus(params) {
    const res = await axios.get('/export/maintanence-detail-status', { params });
    return res;
}
export async function exportMaintenanceDetail(params) {
    const res = await axios.get('/export/maintanence-detail', { params });
    return res;
}

//Errors
export async function getErrors(params) {
    const res = await axios.get('/errors/list', { params });
    return res.data;
}
export async function createErrors(params) {
    const res = await axios.post('/errors/create', params);
    return res.data;
}
export async function updateErrors(params) {
    const res = await axios.patch('/errors/update', params);
    return res.data;
}
export async function deleteErrors(params) {
    const res = await axios.post('/errors/delete', params);
    return res.data;
}
export async function exportErrors(params) {
    const res = await axios.get('/errors/export', { params });
    return res;
}

//TestCriteria
export async function getTestCriteria(params) {
    const res = await axios.get('/test_criteria/list', { params });
    return res.data;
}
export async function createTestCriteria(params) {
    const res = await axios.post('/test_criteria/create', params);
    return res.data;
}
export async function updateTestCriteria(id, params) {
    const res = await axios.patch('/test_criteria/update/'+id, params);
    return res.data;
}
export async function deleteTestCriteria(params) {
    const res = await axios.post('/test_criteria/delete', params);
    return res.data;
}
export async function exportTestCriteria(params) {
    const res = await axios.get('/test_criteria/export', { params });
    return res;
}
export async function importTestCriteria(params) {
    const res = await axios.post('/test_criteria/import', params, { headers: { "Content-Type": "multipart/form-data" } });
    return res;
}

//Line
export async function getLine(params) {
    const res = await axios.get('/cong-doan/list', { params });
    return res.data;
}
export async function createLine(params) {
    const res = await axios.post('/cong-doan/create', params);
    return res.data;
}
export async function updateLine(params) {
    const res = await axios.patch('/cong-doan/update', params);
    return res.data;
}
export async function deleteLine(params) {
    const res = await axios.post('/cong-doan/delete', params);
    return res.data;
}
export async function exportLine(params) {
    const res = await axios.get('/cong-doan/export', { params });
    return res;
}

//Users
export async function getUsers(params) {
    const res = await axios.get('/users/list', { params });
    return res.data;
}
export async function getUserRoles(params) {
    const res = await axios.get('/users/roles', { params });
    return res.data;
}
export async function createUsers(params) {
    const res = await axios.post('/users/create', params);
    return res.data;
}
export async function updateUsers(params) {
    const res = await axios.patch('/users/update', params);
    return res.data;
}
export async function deleteUsers(params) {
    const res = await axios.post('/users/delete', params);
    return res.data;
}
export async function exportUsers(params) {
    const res = await axios.get('/users/export', { params });
    return res;
}

//Roles
export async function getRoles(params) {
    const res = await axios.get('/roles/list', { params });
    return res.data;
}
export async function getRolePermissions(params) {
    const res = await axios.get('/roles/permissions', { params });
    return res.data;
}
export async function createRoles(params) {
    const res = await axios.post('/roles/create', params);
    return res.data;
}
export async function updateRoles(params) {
    const res = await axios.patch('/roles/update', params);
    return res.data;
}
export async function deleteRoles(params) {
    const res = await axios.post('/roles/delete', params);
    return res.data;
}
export async function exportRoles(params) {
    const res = await axios.get('/roles/export', { params });
    return res;
}

//Permissions
export async function getPermissions(params) {
    const res = await axios.get('/permissions/list', { params });
    return res.data;
}
export async function createPermissions(params) {
    const res = await axios.post('/permissions/create', params);
    return res.data;
}
export async function updatePermissions(params) {
    const res = await axios.patch('/permissions/update', params);
    return res.data;
}
export async function deletePermissions(params) {
    const res = await axios.post('/permissions/delete', params);
    return res.data;
}
export async function exportPermissions(params) {
    const res = await axios.get('/permissions/export', { params });
    return res;
}

export async function exportKHSX(params) {
    const res = await axios.get('/production_plan/export', { params });
    return res;
}

export async function getDetailAbnormal(params) {
    const res = await axios.get('/abnormal/detail', { params });
    return res.data;
}

export async function getFcPlant(params) {
    const res = await axios.get('/p2/ui/fc-plants', { params });
    return res;
}

export async function getWarehouseExportPlan(params) {
    const res = await axios.get('/p2/ui/warehouse-export-plans', { params });
    return res;
}

export async function updateWHExport(params) {
    const res = await axios.post('/p2/ui/warehouse-export-plans/update', params);
    return res;
}

export async function deleteWHExport(params) {
    const res = await axios.post('/p2/ui/warehouse-export-plans/delete', params);
    return res;
}

export async function approvalWHExport(params) {
    const res = await axios.post('/p2/ui/warehouse-export-plans/approval', params);
    return res;
}